.crosshair{

    &__wrapper{
        width: 10px;
        height: 10px;
        position: fixed;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        background-color: var(--purple);
        border-radius: 50%;
        z-index: 3;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        &.active{
            background-color: #FFFFFF;
        }
    }

    &__corner{
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        &.top-left{
            top: -20px;
            left: -20px;
            border-right: 1px solid var(--purple);
            transform-origin: center;
            transform: rotate(45deg);
            &.active{
                top: -25px;
                left: -25px;
                border-color: #FFFFFF;
            }
        }
        &.top-right{
            top: -20px;
            left: 10px;
            border-left: 1px solid var(--purple);
            transform-origin: center;
            transform: rotate(-45deg);
            &.active{
                top: -25px;
                left: 15px;
                border-color: #FFFFFF;
            }
        }
        &.bottom-left{
            top: 10px;
            left: -20px;
            border-right: 1px solid var(--purple);
            transform-origin: center;
            transform: rotate(-45deg);
            &.active{
                top: 15px;
                left: -25px;
                border-color: #FFFFFF;
            }
        }
        &.bottom-right{
            top: 10px;
            left: 10px;
            border-left: 1px solid var(--purple);
            transform-origin: center;
            transform: rotate(45deg);
            &.active{
                top: 15px;
                left: 15px;
                border-color: #FFFFFF;
            }
        }
    }

    &__tooltip-wrapper{
        position: fixed;
        top: calc(50% + 30px);
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
        text-align: center;
        z-index: 3;
        padding: 5px 10px;
        background-color: rgba(195, 195, 195, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
        opacity: 0;
        &.active{
            opacity: 1;
        }
    }


}