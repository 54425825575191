@import "../../styles/breakpoints";

.mute-button {
  &__wrapper {
    width: 110px;
    text-align: center;
    margin: 10px;
    position: fixed;
    bottom: 70px;
    right: 0;
    padding: 5px 10px;
    background-color: rgba(195, 195, 195, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    z-index: 3;
  }

  &__outer {
    height: 20px;
    padding: 3px;
    width: 100%;
    border-left: 1px solid var(--purple);
    border-right: 1px solid var(--purple);
    margin-bottom: 10px;
    border-radius: 10px;
    margin-top: 5px;
    position: relative;
  }
  &__inner {
    height: 100%;
    width: 50%;
    border-radius: 10px;
    background-color: var(--purple);
    position: relative;
    z-index: 1;
    transition: all 0.2s ease-in-out;
  }
  &__text {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    text-align: center;
    position: absolute;
    top: 0;
    &.off {
      left: 0;
    }
    &.on {
      right: 0;
    }
  }
}

@include mobile {
  .mute-button {
    &__wrapper {
      bottom: auto;
      right: auto;
      left: 0;
      top: 30px;
    }
  }
}
