.loadbar{

    &__wrapper{
        position: fixed;
        top: calc(50% + 60px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        text-align: center;
        padding: 10px ;
        background-color: rgba(195, 195, 195, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        border-radius: 10px;
        pointer-events: none;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        &.active{
            opacity: 1;
        }
    }

    &__outer{
        width: 150px;
        height: 10px;
        padding: 3px;
        border-left: 1px solid var(--purple);
        border-right: 1px solid var(--purple);
        margin-bottom: 10px;
        border-radius: 10px;
    }

    &__inner{
        height: 100%;
        background-color: var(--purple);
        border-radius: 10px;
    }
}