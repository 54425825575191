@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-change1 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes opacity-change2 {
  0%,
  100%,
  50% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.5;
  }
}

@keyframes opacity-change3 {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes left-right {
  0% {
    left: 0%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 0%;
  }
}

@keyframes red-pulse {
  0% {
    background-color: var(--purple);
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: var(--purple);
  }
}

@keyframes pulse-in {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
