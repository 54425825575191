@import "../../styles/animations";
@import "../../styles/helpers";

.dots {
  &__wrapper {
    margin: 10px;
    padding: 35px;
    width: 60px;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    background-color: rgba(195, 195, 195, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
  }
  &__pentagon {
    width: 60px;
    height: 60px;
    background: var(--purple);
    clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
    transition: opacity 2s ease-in-out;
    position: absolute;
    &.active {
      opacity: 0;
      pointer-events: none;
      animation: rotate 1s linear infinite;
    }
  }

  &__completed-wrapper {
    width: 60px;
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity 2s ease-in-out;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__completed-icon {
    display: block;
    width: 60px;
    height: 60px;
    top: 4px;
    left: -2px;
    position: absolute;
    transform-origin: center;
    animation: rotate 1s linear infinite;
    opacity: 0.2;
    pointer-events: none;
  }

  &__unlock-button{
    font-size: 10px;
    line-height: 12px;
    padding: 5px;
    border-left: 1px solid var(--purple);
    border-right: 1px solid var(--purple);
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    @include hover{
      background-color: var(--purple);
      color: #FFF;
    }
  }

  &__dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 1px solid var(--purple);
    position: absolute;

    &.Bass {
      top: 10px;
      left: 54px;
    }
    &.Drums {
      top: 40px;
      right: 10px;
    }
    &.Pads {
      bottom: 2px;
      right: 25px;
    }
    &.Vocals {
      bottom: 2px;
      left: 25px;
    }
    &.Synth {
      top: 40px;
      left: 10px;
    }
  }
  &__inner-dot {
    width: 10px;
    height: 10px;
    margin: 5px;
    border-radius: 50%;
    background-color: var(--purple);
  }

  &__text-wrapper {
    width: calc(100% - 20px);
    height: 14px;
    text-align: center;
    pointer-events: none;
    position: absolute;
    bottom: -35px;
    left: 0;
    padding: 5px 10px;
    background-color: rgba(195, 195, 195, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
}
