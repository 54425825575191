*::selection {
  background: rgba(255, 255, 255, 0.99);
  color: var(--purple);
}
*::-moz-selection {
  background: rgba(255, 255, 255, 0.99);
  color: var(--purple);
}

*::-webkit-scrollbar {
  width: 0px;
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: var(--purple);
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border: none;
}



html,
body {
  width: 100%;
  height: 100%;
  color: var(--purple);
}

.canvas__wrapper{
  width: 100%;
  height: var(--app-height);
  position: fixed;
  top: 0;
  left: 0;
}
