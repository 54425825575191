@import "../../styles/animations";
@import "../../styles/breakpoints";

.instructions {
  &__blocker {
    cursor: pointer;
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--app-height);
    z-index: 4;
    display: none;
    text-align: center;
    &.active {
      display: block;
    }
  }

  &__wrapper {
    position: fixed;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 10px 10px;
    background-color: rgba(195, 195, 195, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
  &__title {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  &__inner {
    width: calc(100% - 42px);
    height: 100%;
    padding: 20px;
    border-left: 1px solid var(--purple);
    border-right: 1px solid var(--purple);
    border-radius: 10px;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__help-button {
    position: fixed;
    margin: 10px;
    width: 130px;
    bottom: 35px;
    right: 0;
    padding: 5px 20px;
    background-color: rgba(195, 195, 195, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    z-index: 3;
    text-align: center;
  }

  &__icons-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  &__icons-half {
    width: calc(50% - 60px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 30px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
  }

  &__key {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    border-left: 1px solid var(--purple);
    border-right: 1px solid var(--purple);
    border-radius: 5px;
    position: relative;
  }

  &__character {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.letter {
      animation: pulse-in 2s linear infinite;
    }
    &.arrow {
      animation: pulse-out 2s linear infinite;
    }
  }

  &__mouse-outer {
    border-radius: 10px 10px 3px 3px;
    width: 30px;
    height: 50px;
    border: 1px solid var(--purple);
    position: relative;
    animation: left-right 3s ease-in-out infinite;
  }

  &__mouse-inner {
    height: 20px;
    width: 14.5px;
    border-right: 1px solid var(--purple);
    border-bottom: 1px solid var(--purple);
    display: inline-block;
    border-radius: 10px 0 0 0;
    animation: red-pulse 2s linear infinite;
    &.right {
      border-right: none;
      animation: none;
    }
  }

  &__text {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
  }

  &__mouse-arrow {
    position: absolute;
    top: 50%;
    &.left {
      left: -20px;
    }
    &.right {
      right: -20px;
    }
  }
}


@include mobile{

  .instructions{

    &__wrapper {
      left: 0;
      transform: translate(0, -50%);
      width: calc(100% - 40px);
      margin: 10px;
    }

    &__help-button {
      bottom: auto;
      right: auto;
      left: 0;
      top: 95px;
    }

    &__mouse-outer {
      border-radius: 3px;
      width: 30px;
      height: 50px;
    }

    &__mouse-inner {
      height: 5px;
      width: 5px;
      border: none;
      display: block;
      border-radius: 50%;
      background-color: var(--purple);
      animation: none;
      position: absolute;
      bottom: 2px;
      left: 12.5px;
      &.right {
        display: none;
      }
    }
  }
}