@import "../../styles/breakpoints";

.credits{

    &__wrapper{
        position: fixed;
        margin: 10px;
        width: 110px;
        bottom: 0;
        right: 0;
        padding: 5px 10px;
        background-color: rgba(195, 195, 195, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        border-radius: 10px;
        z-index: 3;
        text-align: center;
    }
}

@include mobile{
    .credits{

        &__wrapper{
            bottom: auto;
            right: auto;
            top: 125px;
            left: 0;
        }
    }

}