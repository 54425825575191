.mobile-arrows {
  &__wrapper {
    margin: 10px;
    width: calc(100% - 20px);
    position: fixed;
    bottom: 30px;
    left: 0;
    z-index: 3;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    &.active{
      opacity: 1;
      pointer-events: all;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
  }

  &__key {
    width: 60px;
    height: 60px;
    margin: 0 5px;
    padding: 10px 10px;
    display: block;
    background-color: rgba(195, 195, 195, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__key-inner {
    width: calc(100% - 2px);
    height: 100%;
    border-left: 1px solid var(--purple);
    border-right: 1px solid var(--purple);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    pointer-events: none;
  }

  &__character {
    text-align: center;
    pointer-events: none;
  }
}
