@font-face {
  font-family: 'Neue Power';
  src: url('../assets/fonts/NeuePower-Medium.eot');
  src: url('../assets/fonts/NeuePower-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/NeuePower-Medium.woff2') format('woff2'),
      url('../assets/fonts/NeuePower-Medium.woff') format('woff'),
      url('../assets/fonts/NeuePower-Medium.ttf') format('truetype'),
      url('../assets/fonts/NeuePower-Medium.svg#NeuePower-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: 'Neue Power', sans-serif;
  font-size: 12px;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    text-decoration: none
  }
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}
